import {withAuthenticator} from "@aws-amplify/ui-react";
import {translations} from '@aws-amplify/ui-react';

import {I18n} from 'aws-amplify';
import {WithAuthenticatorProps} from "@aws-amplify/ui-react/dist/types/components/Authenticator/withAuthenticator";
import {AppProps} from "next/app";
import {useEffect} from "react";
import { useRouter } from 'next/router'

I18n.putVocabularies(translations);
I18n.setLanguage('fr');
I18n.putVocabularies({
    fr: {
        'User does not exist.': "L'utilisateur n'existe pas.",
        'Incorrect username or password.' : "Utilisateur ou mot de passe inconnu.",
        "Enter your Username" : "Saisissez votre nom d'utilisateur",
        "Enter your Password" : "Saisissez votre mot de passe",
        'Please confirm your Password' : 'Veuillez confirmer votre mot de passe',
        'Enter your Email' : 'Saisissez votre email',
    }
});

function Home({user}: AppProps & WithAuthenticatorProps) {
    const router = useRouter()
    useEffect(() =>{
       user && router.push('currentSeance')
    },[router, user])
    return (
        <div style={{padding: 50}}>
        </div>
    );
}

export default withAuthenticator(Home);
